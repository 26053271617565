import { styled } from '@mui/system';

export const PageContainer = styled('div')({
  position: 'relative',
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
