import React, { ComponentType, PropsWithChildren, useContext, useEffect } from 'react';

import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { Fade, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { MainContainer } from '@/components/common/Container/Container';
import { InstructionOverlayContext } from '@/components/providers/InstructionOverlayProvider';

import { commonQueries } from '@/queries/common';

export const Content = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(6),
  '.highlighted': {
    color: theme.palette.primary.main,
  },
}));

export interface InstructionProps {
  Icon: ComponentType;
  instructionTextKey: string;
  actionButtonLabelKey?: string;
}

export const InstructionOverlay = ({
  children,
  actionButtonLabelKey,
  Icon,
  instructionTextKey,
}: PropsWithChildren<Partial<InstructionProps>>) => {
  const { t } = useTranslation();
  const { isInstructionOverlayOpen, setIsInstructionOverlayOpen } = useContext(InstructionOverlayContext);
  const router = useRouter();
  const { data: serviceProviderIdData } = commonQueries.useServiceProviderId({
    processId: router.query?.processId as string,
  });
  const { data: serviceProviderData } = commonQueries.useServiceProviderData({
    serviceProviderId: serviceProviderIdData?.serviceProviderId || '',
  });

  useEffect(() => {
    if (instructionTextKey) {
      setIsInstructionOverlayOpen(true, instructionTextKey);
    }
  }, [instructionTextKey, setIsInstructionOverlayOpen]);

  const handleOverlayClose = () => setIsInstructionOverlayOpen(false);

  if (!isInstructionOverlayOpen || !Icon || !instructionTextKey || !serviceProviderData?.signHubMaterialUIEnabled) {
    return (
      <Fade in>
        <Box width="100%">{children}</Box>
      </Fade>
    );
  }

  return (
    <Fade in>
      <Box width="100%">
        <MainContainer
          nextButtonLabel={t<string>(actionButtonLabelKey || 'button.confirm')}
          handleClickNext={handleOverlayClose}>
          <Content>
            <Icon />
            <Typography variant="h6" textAlign="center" dangerouslySetInnerHTML={{ __html: t(instructionTextKey) }} />
          </Content>
        </MainContainer>
      </Box>
    </Fade>
  );
};
