import Fab from '@mui/material/Fab';
import { styled } from '@mui/system';

export const ButtonContainer = styled(Fab)({
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
  borderRadius: '16px',
  height: '56px',
  '&:hover': {
    boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)',
  },
});
