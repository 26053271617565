import React, { FC } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CircularProgress } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

import { ButtonContainer } from './style';

interface FloatingActionButtonProps {
  label?: string;
  Icon?: typeof SvgIcon;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
}

export const FloatingActionButton: FC<FloatingActionButtonProps> = props => {
  const { label, onClick, loading, testId, Icon = NavigateNextIcon } = props;

  return (
    <ButtonContainer
      type="submit"
      size="large"
      variant="extended"
      color="primary"
      onClick={onClick}
      disabled={loading}
      data-testid={testId}>
      {loading ? <CircularProgress size={'16px'} sx={{ mr: 1 }} /> : <Icon sx={{ mr: 1 }} />}
      {label}
    </ButtonContainer>
  );
};
