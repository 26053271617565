import { Box, Container as MuiContainer, Grid } from '@mui/material';
import { styled } from '@mui/system';

export const ContentWrapper = styled(MuiContainer)(({ theme }) => ({
  position: 'relative',
  minHeight: '744px',
  maxWidth: '989px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  backgroundColor: '#FFFFFF',
  borderRadius: '12px',
  paddingBottom: theme.spacing(2),
  marginTop: theme.spacing(7),
  '@media (max-height: 744px)': {
    minHeight: '100vh',
  },
  [theme.breakpoints.down('lg')]: {
    minHeight: '100vh',
    width: '100%',
    marginTop: 0,
  },
}));

export const MainContainerWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 4),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

export const ChildrenContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexBasis: 0,
  padding: theme.spacing(1, '46px'),
  '& > *': {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
    margin: 0,
  },
}));

export const HeaderContainer = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  padding: theme.spacing(4, '46px', 0),
  backgroundColor: '#FFF',
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    zIndex: 1,
    padding: theme.spacing(0, 2),
    position: 'sticky',
    top: theme.spacing(8),
    borderRadius: 0,
  },
}));

export const HeaderItems = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

export const NextButtonContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  alignSelf: 'flex-end',
  marginRight: theme.spacing(2),
  bottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}));
