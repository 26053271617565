import { ComponentType, FC, PropsWithChildren } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, SvgIcon, Typography } from '@mui/material';

import { FloatingActionButton } from '@/components/FloatingActionButton/FloatingActionButton';
import {
  ChildrenContainer,
  ContentWrapper,
  HeaderContainer,
  HeaderItems,
  MainContainerWrapper,
  NextButtonContainer,
} from '@/components/common/Container/styles';

export interface MainContainerProps extends PropsWithChildren {
  headerTitle?: string;
  headerSubtitle?: string | null;
  HeaderIcon?: ComponentType | false;
  handleClickBack?: () => void;
  handleClickNext?: () => void;
  nextButtonLabel?: string;
  nextButtonIcon?: typeof SvgIcon;
  loading?: boolean;
}

export const MainContainer: FC<MainContainerProps> = ({
  children,
  HeaderIcon,
  headerTitle,
  headerSubtitle,
  handleClickBack,
  handleClickNext,
  nextButtonLabel,
  nextButtonIcon,
  loading,
}) => {
  return (
    <MainContainerWrapper>
      <ContentWrapper maxWidth="lg" disableGutters>
        {headerTitle && (
          <HeaderContainer container>
            <HeaderItems container item xs spacing={2}>
              {handleClickBack && (
                <Grid item>
                  <IconButton sx={{ padding: 0 }} onClick={handleClickBack} data-testid="go-back-button">
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography variant={'h5'} data-testid="header-title">
                  {headerTitle}
                </Typography>
              </Grid>
              {headerSubtitle && (
                <Grid item>
                  <Typography variant={'body1'} data-testid="header-subtitle">
                    {headerSubtitle}
                  </Typography>
                </Grid>
              )}
            </HeaderItems>

            {HeaderIcon && <HeaderIcon />}
          </HeaderContainer>
        )}

        <ChildrenContainer>{children}</ChildrenContainer>
        {handleClickNext && (
          <NextButtonContainer>
            <FloatingActionButton
              loading={loading}
              onClick={handleClickNext}
              Icon={nextButtonIcon}
              label={nextButtonLabel}
              testId="submit-button"
            />
          </NextButtonContainer>
        )}
      </ContentWrapper>
    </MainContainerWrapper>
  );
};
