import { ForwardedRef, forwardRef, isValidElement, ReactNode, useCallback, useImperativeHandle, useState } from 'react';

import { Button, Dialog, Grid, Typography } from '@mui/material';
import { Breakpoint, Stack } from '@mui/system';

import { useTestIdGenerator } from '@/hooks/useTestIdGenerator';

export interface ErrorDialogProps {
  icon?: ReactNode;
  title?: string;
  subtitle?: ReactNode | string;
  buttonSubmit?: string;
  buttonClose?: string;
  onSubmit?: () => void;
  onClose?: () => void;
  maxWidth?: Breakpoint;
  width?: Partial<Record<Breakpoint, string | number>>;
}

export interface ErrorDialogRef {
  open: (params: Partial<ErrorDialogProps>) => void;
  close: () => void;
}

export const ErrorDialog = forwardRef<ErrorDialogRef, ErrorDialogProps>(
  (initialProps: ErrorDialogProps, ref: ForwardedRef<ErrorDialogRef>) => {
    const [open, setOpen] = useState(false);
    const [props, setProps] = useState(initialProps);
    const generateTestId = useTestIdGenerator('error-dialog');

    const { icon, title, subtitle, buttonSubmit, buttonClose, onSubmit, maxWidth = 'xs', width } = props;

    const onClose = useCallback(() => {
      props.onClose?.();
      setOpen(false);
    }, [props]);

    const onOpen = useCallback(
      (params: Partial<ErrorDialogProps>) => {
        setProps({
          ...initialProps,
          ...params,
        });
        setOpen(true);
      },
      [initialProps],
    );

    useImperativeHandle(
      ref,
      () => ({
        open: onOpen,
        close: onClose,
      }),
      [onOpen, onClose],
    );

    return (
      <Dialog
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: { borderRadius: '28px' },
        }}
        maxWidth={maxWidth}>
        <Grid sx={{ padding: '24px', width: width || { xs: 'auto' } }}>
          <Grid display="flex" justifyContent="center" data-testid={generateTestId('icon')}>
            {icon}
          </Grid>
          <Stack spacing={2} direction="column" pt={'16px'}>
            {title && (
              <Typography data-testid={generateTestId('title')} variant="h5" sx={{ textAlign: 'center' }}>
                {title}
              </Typography>
            )}
            {isValidElement(subtitle) ? (
              subtitle
            ) : (
              <Typography data-testid={generateTestId('subtitle')} variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {subtitle}
              </Typography>
            )}
          </Stack>
          <Stack spacing={1} direction="column" alignItems="flex-end" marginTop="24px">
            {onSubmit ? (
              <Button
                data-testid={generateTestId('submit-button')}
                sx={{ width: 'auto', height: '50px', textTransform: 'capitalize' }}
                variant="text"
                onClick={onSubmit}>
                {buttonSubmit}
              </Button>
            ) : null}
            {buttonClose && (
              <Button
                data-testid={generateTestId('close-button')}
                sx={{ width: 'auto', height: '44px', minWidth: 'unset', textTransform: 'capitalize' }}
                variant="text"
                onClick={onClose}>
                {buttonClose}
              </Button>
            )}
          </Stack>
        </Grid>
      </Dialog>
    );
  },
);

ErrorDialog.displayName = 'ErrorDialog';
