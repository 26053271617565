import { Button } from 'frontend-ui';
import { redirectIdentByStatus } from 'src/services/ident/ident-service';

import { useForm } from 'react-hook-form';

import { gsspWithNonce } from '@next-safe/middleware/dist/document';
import { GetServerSidePropsContext } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Stack, TextField } from '@mui/material';
import { styled } from '@mui/system';

import { dehydrate, QueryClient } from '@tanstack/react-query';

import { ApiError } from '@/api/axios';

import { MainContainer } from '@/components/common/Container/Container';
import { Page } from '@/components/common/Page/Page';
import { BaseLayout } from '@/components/layouts/BaseLayout';

import { commonQueries } from '@/queries/common';
import { identQueries } from '@/queries/ident';

import { RouteName, WaasRouteName } from '@/routes/routes';

import { isWaas } from '@/utils/environment';

const FormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export type HomePageQueryParams = {
  processId?: string;
};

interface HomeProps {
  processId?: string;
  isWaasApp: boolean;
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { query, locale } = context;
  const { processId } = query as HomePageQueryParams;

  const queryClient = new QueryClient();

  if (processId) {
    try {
      const redirect = await redirectIdentByStatus(context, queryClient);
      if (redirect) {
        return redirect;
      }
    } catch (error) {
      if (error instanceof ApiError) {
        console.error('Process not found', error.detail);
      }
    }
  }

  return gsspWithNonce(async () => ({
    props: {
      processId,
      isWaasApp: isWaas(),
      dehydratedState: dehydrate(queryClient),
      ...(await serverSideTranslations(locale as string, ['common', 'home', 'errors'])),
    },
  }))(context);
};

export default function Home(props: HomeProps) {
  const { processId, isWaasApp } = props;
  const {
    register,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  commonQueries.useStatuses({ processId });

  if (!isWaasApp) {
    identQueries.useRetrieveUserDetails({ processId });
  }

  return (
    <Page>
      <MainContainer>
        <FormContainer action={isWaasApp ? WaasRouteName.WaasLogin : RouteName.IdentRegistration}>
          <Stack spacing={2} width="350px">
            <TextField
              id="outlined-basic"
              label="Process id"
              variant="outlined"
              size="small"
              fullWidth
              autoComplete="on"
              {...register('processId', { required: true })}
            />
            {errors.processId && <span>{t('form.errors.required')}</span>}
            <Button variant="contained" type="submit">
              {t('button.submit')}
            </Button>
          </Stack>
        </FormContainer>
      </MainContainer>
    </Page>
  );
}

Home.PageLayout = BaseLayout;
