import * as React from 'react';
import { FC, Fragment, PropsWithChildren, useContext, useEffect, useRef } from 'react';

import Head from 'next/head';

import { FullScreenLoader } from '@/components/common/FullScreenLoader/FullScreenLoader';
import { InstructionOverlay, InstructionProps } from '@/components/common/InstructionOverlay/InstructionOverlay';
import { ErrorDialog, ErrorDialogProps, ErrorDialogRef } from '@/components/dialogs/ErrorDialog';
import { GlobalErrorContext } from '@/components/providers/GlobalErrorProvider';

import { usePageTitle } from '@/hooks/usePageTitle';

import { PageContainer } from './styles';

interface PageProps extends PropsWithChildren {
  pageTitle?: string;
  loading?: boolean;
  instructionOverlayConfig?: InstructionProps;
  error?: {
    dialogParams: ErrorDialogProps;
    isError: boolean;
    hideChildren?: boolean;
  };
}

export const Page: FC<PageProps> = ({ pageTitle, children, error, loading, instructionOverlayConfig }: PageProps) => {
  const dialogRef = useRef<ErrorDialogRef>(null);
  const globalError = useContext(GlobalErrorContext);

  useEffect(() => {
    if (globalError?.isError) {
      dialogRef.current?.open(globalError.errorDialogParams);
    } else if (error?.isError) {
      dialogRef.current?.open(error.dialogParams);
    } else {
      dialogRef.current?.close();
    }
  }, [error, globalError]);

  const title = usePageTitle(pageTitle);
  const isRenderChildren = !error?.hideChildren && !globalError?.hideChildren;

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
      </Head>
      <PageContainer>
        {isRenderChildren && <InstructionOverlay {...instructionOverlayConfig}>{children}</InstructionOverlay>}
        <ErrorDialog ref={dialogRef} />
        <FullScreenLoader loading={loading} />
      </PageContainer>
    </Fragment>
  );
};
