import React, { FC } from 'react';

import { CircularProgress } from '@mui/material';

import { Backdrop } from './styles';

interface FullScreenLoaderProps {
  loading?: boolean;
}

export const FullScreenLoader: FC<FullScreenLoaderProps> = ({ loading }) => {
  return (
    <Backdrop open={!!loading}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
